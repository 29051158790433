<template>
  <div>
    <CientificaImporta />
  </div>
</template>

<script>
import CientificaImporta from "@/components/documentacaoApoio/CientificaImporta.vue";

export default {
  components: {
    CientificaImporta
  }
};
</script>
